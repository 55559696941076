import { createRouter, createWebHistory } from "vue-router";

// import store from "../store";

const Dashboard = () => import("@/components/Dashboard");
const Home = () => import("@/components/Home");
const ContactUs = () => import("@/components/ContactUs");
const Redirect = () => import("@/components/Redirect");
const PrivacyPolicy = () => import("@/components/PrivacyPolicy");
const News = () => import("@/components/News");
const About = () => import("@/components/About");

const News2020120801 = () => import("@/components/news/News2020120801");
const News2023072001 = () => import("@/components/news/News2023072001");
const News2023082001 = () => import("@/components/news/News2023082001");
const News2023090101 = () => import("@/components/news/News2023090101");
const News2023100101 = () => import("@/components/news/News2023100101");

const Pos = () => import("@/views/Pos");
const Vas = () => import("@/views/Vas");

const EInvoiceCashier = () => import("@/components/ecr/EInvoiceCashier");
const EInvoiceCashierDetail = () =>
  import("@/components/ecr/EInvoiceCashierDetail");
const DuplicateUniformInvoice = () =>
  import("@/components/ecr/DuplicateUniformInvoice");
const DuplicateUniformInvoiceDetail = () =>
  import("@/components/ecr/DuplicateUniformInvoiceDetail");
const TriplicateUniformInvoice = () =>
  import("@/components/ecr/TriplicateUniformInvoice");
const TriplicateUniformInvoiceDetail = () =>
  import("@/components/ecr/TriplicateUniformInvoiceDetail");

const BusinessMachine = () => import("@/components/oa/BusinessMachine");
const BusinessMachineDetail = () =>
  import("@/components/oa/BusinessMachineDetail");
const CheckMachine = () => import("@/components/oa/CheckMachine");
const CheckMachineDetail = () => import("@/components/oa/CheckMachineDetail");
const CoinCountingMachine = () => import("@/components/oa/CoinCountingMachine");
const CoinCountingMachineDetail = () =>
  import("@/components/oa/CoinCountingMachineDetail");
const Laminator = () => import("@/components/oa/Laminator");
const LaminatorDetail = () => import("@/components/oa/LaminatorDetail");
const MoneyCounter = () => import("@/components/oa/MoneyCounter");
const MoneyCounterDetail = () => import("@/components/oa/MoneyCounterDetail");
const PaperShredder = () => import("@/components/oa/PaperShredder");
const PaperShredderDetail = () => import("@/components/oa/PaperShredderDetail");
const PrintedClock = () => import("@/components/oa/PrintedClock");
const PrintedClockDetail = () => import("@/components/oa/PrintedClockDetail");
const PunchClock = () => import("@/components/oa/PunchClock");
const PunchClockDetail = () => import("@/components/oa/PunchClockDetail");
const Stapler = () => import("@/components/oa/Stapler");
const StaplerDetail = () => import("@/components/oa/StaplerDetail");

const Printer = () => import("@/components/merch/Printer");
const PrinterDetail = () => import("@/components/merch/PrinterDetail");
const CarbonRibbon = () => import("@/components/merch/CarbonRibbon");
const CarbonRibbonDetail = () =>
  import("@/components/merch/CarbonRibbonDetail");
const Cashbox = () => import("@/components/merch/Cashbox");
const CashboxDetail = () => import("@/components/merch/CashboxDetail");
const ColorRibbon = () => import("@/components/merch/ColorRibbon");
const ColorRibbonDetail = () => import("@/components/merch/ColorRibbonDetail");
const InventoryMachine = () => import("@/components/merch/InventoryMachine");
const InventoryMachineDetail = () =>
  import("@/components/merch/InventoryMachineDetail");
const LabelingMachine = () => import("@/components/merch/LabelingMachine");
const LabelingMachineDetail = () =>
  import("@/components/merch/LabelingMachineDetail");
const PosHost = () => import("@/components/merch/PosHost");
const PosHostDetail = () => import("@/components/merch/PosHostDetail");
const RollOfPaper = () => import("@/components/merch/RollOfPaper");
const RollOfPaperDetail = () => import("@/components/merch/RollOfPaperDetail");
const Scale = () => import("@/components/merch/Scale");
const ScaleDetail = () => import("@/components/merch/ScaleDetail");
const Scanner = () => import("@/components/merch/Scanner");
const ScannerDetail = () => import("@/components/merch/ScannerDetail");
const Sticker = () => import("@/components/merch/Sticker");
const StickerDetail = () => import("@/components/merch/StickerDetail");
const Timecard = () => import("@/components/merch/Timecard");
const TimecardDetail = () => import("@/components/merch/TimecardDetail");
// const  = () => import();

// import Dashboard from "@/components/Dashboard";
// import Home from "@/components/Home";
// import ContactUs from "@/components/ContactUs";
// import PrivacyPolicy from "@/components/PrivacyPolicy";
// import News from "@/components/News";
// import About from "@/components/About";

//NEWS
// import News2020120801 from "@/components/news/News2020120801";
// import News2023072001 from "@/components/news/News2023072001";
// import News2023082001 from "@/components/news/News2023082001";

// POS
// import Pos from "@/views/Pos";
// import Vas from "@/views/Vas";

//VAS

//ECR
// import EInvoiceCashier from "@/components/ecr/EInvoiceCashier";
// import EInvoiceCashierDetail from "@/components/ecr/EInvoiceCashierDetail";
// import DuplicateUniformInvoice from "@/components/ecr/DuplicateUniformInvoice";
// import DuplicateUniformInvoiceDetail from "@/components/ecr/DuplicateUniformInvoiceDetail";
// import TriplicateUniformInvoice from "@/components/ecr/TriplicateUniformInvoice";
// import TriplicateUniformInvoiceDetail from "@/components/ecr/TriplicateUniformInvoiceDetail";

//OA
// import BusinessMachine from "@/components/oa/BusinessMachine";
// import BusinessMachineDetail from "@/components/oa/BusinessMachineDetail";
// import CheckMachine from "@/components/oa/CheckMachine";
// import CheckMachineDetail from "@/components/oa/CheckMachineDetail";
// import CoinCountingMachine from "@/components/oa/CoinCountingMachine";
// import CoinCountingMachineDetail from "@/components/oa/CoinCountingMachineDetail";
// import Laminator from "@/components/oa/Laminator";
// import LaminatorDetail from "@/components/oa/LaminatorDetail";
// import MoneyCounter from "@/components/oa/MoneyCounter";
// import MoneyCounterDetail from "@/components/oa/MoneyCounterDetail";
// import PaperShredder from "@/components/oa/PaperShredder";
// import PaperShredderDetail from "@/components/oa/PaperShredderDetail";
// import PrintedClock from "@/components/oa/PrintedClock";
// import PrintedClockDetail from "@/components/oa/PrintedClockDetail";
// import PunchClock from "@/components/oa/PunchClock";
// import PunchClockDetail from "@/components/oa/PunchClockDetail";
// import Stapler from "@/components/oa/Stapler";
// import StaplerDetail from "@/components/oa/StaplerDetail";

//merch
// import Printer from "@/components/merch/Printer";
// import PrinterDetail from "@/components/merch/PrinterDetail";
// import CarbonRibbon from "@/components/merch/CarbonRibbon";
// import CarbonRibbonDetail from "@/components/merch/CarbonRibbonDetail";
// import Cashbox from "@/components/merch/Cashbox";
// import CashboxDetail from "@/components/merch/CashboxDetail";
// import ColorRibbon from "@/components/merch/ColorRibbon";
// import ColorRibbonDetail from "@/components/merch/ColorRibbonDetail";
// import InventoryMachine from "@/components/merch/InventoryMachine";
// import InventoryMachineDetail from "@/components/merch/InventoryMachineDetail";
// import LabelingMachine from "@/components/merch/LabelingMachine";
// import LabelingMachineDetail from "@/components/merch/LabelingMachineDetail";
// import PosHost from "@/components/merch/PosHost";
// import PosHostDetail from "@/components/merch/PosHostDetail";
// import RollOfPaper from "@/components/merch/RollOfPaper";
// import RollOfPaperDetail from "@/components/merch/RollOfPaperDetail";
// import Scale from "@/components/merch/Scale";
// import ScaleDetail from "@/components/merch/ScaleDetail";
// import Scanner from "@/components/merch/Scanner";
// import ScannerDetail from "@/components/merch/ScannerDetail";
// import Sticker from "@/components/merch/Sticker";
// import StickerDetail from "@/components/merch/StickerDetail";
// import Timecard from "@/components/merch/Timecard";
// import TimecardDetail from "@/components/merch/TimecardDetail";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: Dashboard,
      children: [
        {
          name: "Home",
          path: "",
          component: Home,
        },
        {
          name: "UshowPad",
          path: "pos/ushow-pad",
          component: Pos,
        },
        {
          name: "UshowLite",
          path: "pos/ushow-lite",
          component: Pos,
        },
        {
          name: "UshowMini",
          path: "pos/ushow-mini",
          component: Pos,
        },
        {
          name: "Ushow",
          path: "pos/ushow",
          component: Pos,
        },
        {
          name: "UshowWeb",
          path: "pos/ushow-web",
          component: Pos,
        },
        {
          name: "UshowCompare",
          path: "pos/compare",
          component: Pos,
        },
        {
          name: "Invoice",
          path: "vas/einvoice",
          component: Vas,
        },
        {
          name: "Backstage",
          path: "vas/cloud",
          component: Vas,
        },
        {
          name: "Payment",
          path: "vas/multi-payment",
          component: Vas,
        },
        {
          name: "Stock",
          path: "vas/erp",
          component: Vas,
        },
        {
          name: "Customer",
          path: "vas/member",
          component: Vas,
        },
        {
          name: "OnlineOrder",
          path: "vas/online-ordering",
          component: Vas,
        },
        {
          name: "OMO",
          path: "vas/omo",
          component: Vas,
        },
        {
          name: "EInvoiceCashier",
          path: "ecr/einvoice",
          component: EInvoiceCashier,
        },
        {
          name: "EInvoiceCashierDetail",
          path: "ecr/einvoice/:id",
          component: EInvoiceCashierDetail,
        },
        {
          name: "DuplicateUniformInvoice",
          path: "ecr/duplicate",
          component: DuplicateUniformInvoice,
        },
        {
          name: "DuplicateUniformInvoiceDetail",
          path: "ecr/duplicate/:id",
          component: DuplicateUniformInvoiceDetail,
        },
        {
          name: "TriplicateUniformInvoice",
          path: "ecr/triplicate",
          component: TriplicateUniformInvoice,
        },
        {
          name: "TriplicateUniformInvoiceDetail",
          path: "ecr/triplicate/:id",
          component: TriplicateUniformInvoiceDetail,
        },
        {
          name: "BusinessMachine",
          path: "oa/peripheral",
          component: BusinessMachine,
        },
        {
          name: "BusinessMachineDetail",
          path: "oa/peripheral/:id",
          component: BusinessMachineDetail,
        },
        {
          name: "MoneyCounter",
          path: "oa/cash-counter",
          component: MoneyCounter,
        },
        {
          name: "MoneyCounterDetail",
          path: "oa/cash-counter/:id",
          component: MoneyCounterDetail,
        },
        {
          name: "PunchClock",
          path: "oa/punch-clock",
          component: PunchClock,
        },
        {
          name: "PunchClockDetail",
          path: "oa/punch-clock/:id",
          component: PunchClockDetail,
        },
        {
          name: "PaperShredder",
          path: "oa/paper-shredder",
          component: PaperShredder,
        },
        {
          name: "PaperShredderDetail",
          path: "oa/paper-shredder/:id",
          component: PaperShredderDetail,
        },
        {
          name: "CheckMachine",
          path: "oa/check-machine",
          component: CheckMachine,
        },
        {
          name: "CheckMachineDetail",
          path: "oa/check-machine/:id",
          component: CheckMachineDetail,
        },
        {
          name: "Laminator",
          path: "oa/laminator",
          component: Laminator,
        },
        {
          name: "LaminatorDetail",
          path: "oa/laminator/:id",
          component: LaminatorDetail,
        },
        {
          name: "CoinCountingMachine",
          path: "oa/coin-counting-machine",
          component: CoinCountingMachine,
        },
        {
          name: "CoinCountingMachineDetail",
          path: "oa/coin-counting-machine/:id",
          component: CoinCountingMachineDetail,
        },
        {
          name: "PrintedClock",
          path: "oa/time-printer",
          component: PrintedClock,
        },
        {
          name: "PrintedClockDetail",
          path: "oa/time-printer/:id",
          component: PrintedClockDetail,
        },
        {
          name: "Stapler",
          path: "oa/stapler",
          component: Stapler,
        },
        {
          name: "StaplerDetail",
          path: "oa/stapler/:id",
          component: StaplerDetail,
        },
        {
          name: "PosHost",
          path: "peripheral/pos-host",
          component: PosHost,
        },
        {
          name: "PosHostDetail",
          path: "peripheral/pos-host/:id",
          component: PosHostDetail,
        },
        {
          name: "Printer",
          path: "peripheral/printer",
          component: Printer,
        },
        {
          name: "PrinterDetail",
          path: "peripheral/printer/:id",
          component: PrinterDetail,
        },
        {
          name: "LabelingMachine",
          path: "peripheral/labeling-machine",
          component: LabelingMachine,
        },
        {
          name: "LabelingMachineDetail",
          path: "peripheral/labeling-machine/:id",
          component: LabelingMachineDetail,
        },
        {
          name: "Scanner",
          path: "peripheral/scanner",
          component: Scanner,
        },
        {
          name: "ScannerDetail",
          path: "peripheral/scanner/:id",
          component: ScannerDetail,
        },
        {
          name: "InventoryMachine",
          path: "peripheral/inventory-machine",
          component: InventoryMachine,
        },
        {
          name: "InventoryMachineDetail",
          path: "peripheral/inventory-machine/:id",
          component: InventoryMachineDetail,
        },
        {
          name: "ColorRibbon",
          path: "peripheral/color-ribbon",
          component: ColorRibbon,
        },
        {
          name: "ColorRibbonDetail",
          path: "peripheral/color-ribbon/:id",
          component: ColorRibbonDetail,
        },
        {
          name: "CarbonRibbon",
          path: "peripheral/carbon-ribbon",
          component: CarbonRibbon,
        },
        {
          name: "CarbonRibbonDetail",
          path: "peripheral/carbon-ribbon/:id",
          component: CarbonRibbonDetail,
        },
        {
          name: "Sticker",
          path: "peripheral/sticker",
          component: Sticker,
        },
        {
          name: "StickerDetail",
          path: "peripheral/sticker/:id",
          component: StickerDetail,
        },
        {
          name: "RollOfPaper",
          path: "peripheral/roll-of-paper",
          component: RollOfPaper,
        },
        {
          name: "RollOfPaperDetail",
          path: "peripheral/roll-of-paper/:id",
          component: RollOfPaperDetail,
        },
        {
          name: "Cashbox",
          path: "peripheral/cash-box",
          component: Cashbox,
        },
        {
          name: "CashboxDetail",
          path: "peripheral/cash-box/:id",
          component: CashboxDetail,
        },
        {
          name: "Scale",
          path: "peripheral/scale",
          component: Scale,
        },
        {
          name: "ScaleDetail",
          path: "peripheral/scale/:id",
          component: ScaleDetail,
        },
        {
          name: "Timecard",
          path: "peripheral/time-card",
          component: Timecard,
        },
        {
          name: "TimecardDetail",
          path: "peripheral/time-card/:id",
          component: TimecardDetail,
        },
        {
          name: "ContactUs",
          path: "contact-us",
          component: ContactUs,
        },
        {
          name: "Redirect",
          path: "redirect",
          component: Redirect,
        },
        {
          name: "PrivacyPolicy",
          path: "privacy-policy",
          component: PrivacyPolicy,
        },
        {
          name: "News",
          path: "news",
          component: News,
        },
        {
          name: "News2020120801",
          path: `${encodeURI(
            "news/友泰科技加值中心通過ISO27001資訊安全標準認證"
          )}`,
          component: News2020120801,
        },
        {
          name: "News2023072001",
          path: `${encodeURI("news/為什麼我需要POS系統")}`,
          component: News2023072001,
        },
        {
          name: "News2023082001",
          path: `${encodeURI("news/POS系統功能比較、挑選訣竅")}`,
          component: News2023082001,
        },
        {
          name: "News2023090101",
          path: `${encodeURI("news/電子發票的優點")}`,
          component: News2023090101,
        },
        {
          name: "News2023100101",
          path: `${encodeURI("news/如何選擇電子發票加值中心")}`,
          component: News2023100101,
        },
        {
          name: "About",
          path: "about",
          component: About,
        },
      ],
    },
  ],
});

export default router;
